import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { graphql, withPrefix, navigate } from "gatsby";
import Layout from "../components/Layout";
import { HTMLContent } from "../components/Content";
import { copyToClipboard, convertHashtagsToURLFormat } from "../utils/utils";

const SHARABLE_TEXT =
  "Guys, I am really looking to join the @onOccasio African network. If you or someone you know is willing to verify me, please send me an invite.";

const SHARABLE_TEXT_W_HASHTAGS =
  "Guys, I am really looking to join the @onOccasio African network. If you or someone you know is willing to verify me, please send me an invite. #onOCCASIO #onOCCASIOInvite #onOCCASIOReferral";

export const SuccessfulDonationTemplate = ({
  title,
  banner,
  bannerVerticalOffset = 0,
  content,
  contentComponent,
  location,
}) => {
  const PageContent = contentComponent || Content;

  useEffect(() => {
    // gatsby doesn't handle anchors when they are used in Link, this covers it for How it Works page
    setTimeout(() => {
      if (typeof window !== "undefined" && location.hash !== "") {
        const anchors = [...document.querySelectorAll("h3")].filter(
          elem => elem.innerHTML === location.hash.substr(1),
        );
        if (anchors.length !== 0) {
          window.scrollTo(0, anchors[0].offsetTop);
        }
      }
    }, 100);
  }, [location.hash]);

  useEffect(() => {
    // handle FlutterWave return codes
    const urlParams = new URLSearchParams(location.search);

    const isCancelled = urlParams.get("cancelled");
    if (isCancelled === "true") {
      navigate("/donate");
    }

    // if FlutterWave transaction successful, flwref param present
  }, [location.search]);

  return (
    <div className="content">
      <div
        className="full-width-image-container img-top-centered m-t-none m-b-none"
        style={{
          backgroundImage: `url(${
            banner.childImageSharp ? banner.childImageSharp.fluid.src : banner
          })`,
          justifyContent: "flex-end",
          alignItems: "flex-end",
          flexDirection: "row",
          backgroundPosition: `50% ${bannerVerticalOffset}%`,
        }}
      >
        <span
          className="container"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            flexGrow: "1",
          }}
        >
          <h2
            className="has-text-weight-medium is-size-1 has-background-primary-half-opaque m-b-xxl"
            style={{
              color: "white",
              padding: "1rem",
            }}
          >
            {title}
          </h2>
          {/* <h3
        className="has-text-weight-medium is-size-2 has-background-primary-half-opaque m-t-none"
        style={{
          color: "white",
          padding: "1rem",
          marginBottom: "3.5rem ",
        }}
      >
        {subTitle}
      </h3> */}
        </span>
      </div>
      <section className="section section--gradient">
        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <PageContent
                className="content has-text-justified m-b-none"
                content={content}
              />
              <div className="social has-text-centered">
                <a
                  title="facebook"
                  target="_blank"
                  // 500 chars limit on quote
                  href={`https://www.facebook.com/sharer/sharer.php?u=${
                    location.origin
                  }${withPrefix("/")}&hashtag=${convertHashtagsToURLFormat(
                    ["onOCCASIO"],
                    true,
                  )}&quote=${SHARABLE_TEXT}`}
                >
                  <span
                    className="fab fa-facebook-f"
                    alt="facebook"
                    style={{ width: "1em", height: "1em" }}
                  />
                </a>
                <a
                  title="twitter"
                  target="_blank"
                  href={`http://twitter.com/intent/tweet?text=${SHARABLE_TEXT}&hashtags=${convertHashtagsToURLFormat(
                    ["onOCCASIO", "onOCCASIOInvite", "onOCCASIOReferral"],
                  )}`}
                >
                  <span
                    className="fab fa-twitter"
                    alt="twitter"
                    style={{ width: "1em", height: "1em" }}
                  />
                </a>
                <a
                  title="whatsapp"
                  target="_blank"
                  href={`https://api.whatsapp.com/send?text=${SHARABLE_TEXT_W_HASHTAGS}`}
                >
                  <span
                    className="fab fa-whatsapp"
                    alt="whatsapp"
                    style={{ width: "1em", height: "1em" }}
                  />
                </a>
                <a
                  title="show text"
                  className="is-tooltip-bottom is-tooltip-multiline"
                  data-tooltip={`Following text copied: ${SHARABLE_TEXT_W_HASHTAGS}`}
                  id="share-text-btn"
                  onClick={() => {
                    copyToClipboard(SHARABLE_TEXT_W_HASHTAGS);
                    const urlDiv = document.getElementById("share-text-btn");
                    urlDiv.classList.add("tooltip", "is-tooltip-active");
                    setTimeout(() => {
                      urlDiv.classList.remove("tooltip", "is-tooltip-active");
                    }, 10000);
                  }}
                >
                  <span
                    className="far fa-copy"
                    alt="show text"
                    style={{ width: "1em", height: "1em" }}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

SuccessfulDonationTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  banner: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  bannerVerticalOffset: PropTypes.number,
  content: PropTypes.string,
  location: PropTypes.object,
  contentComponent: PropTypes.func,
};

const SuccessfulDonationPage = ({ data, location }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <SuccessfulDonationTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        banner={post.frontmatter.banner}
        bannerVerticalOffset={post.frontmatter.bannerVerticalOffset}
        content={post.html}
        location={location}
      />
    </Layout>
  );
};

SuccessfulDonationPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object,
};

export default SuccessfulDonationPage;

export const successfulDonationPageQuery = graphql`
  query successfulDonation($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        banner {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        bannerVerticalOffset
      }
    }
  }
`;
